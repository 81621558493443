
$(function(){

    $('.log-in-form').submit(function(e){
        var $this = $(this);
        $this.find('.button').addClass('disabled');
    
        e.preventDefault();
    
        $.ajax({
            url: $this.attr('action'),
            type: $this.attr('method'),
            data: $this.serialize(),
            error: function(xhr, textStatus, error){
                console.log(error);
            }
        }).done(function(res){
            if(res.error){
                $('.form-message').text(res.error)
                return;
            }
            window.location.reload();
        })
    })

})
